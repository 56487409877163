<template>
  <div
    ref="modalRef"
    class="modal fade"
    id="filter"
    tabindex="-1"
    aria-labelledby="filter"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header fs-3">Filter {{ name }}</div>
        <div class="modal-body px-12">
          <form @submit.prevent="null">
            <div :key="i" v-for="(item, i) in data">
              <label :for="item[0].name" class="mb-3 fs-5">{{
                item[0].name
              }}</label>
              <el-select
                :id="item[0].name"
                class="w-100 mb-4"
                filterable
                clearable
                :placeholder="item[0].name"
                v-model="models[i]"
              >
                <el-option
                  :key="j"
                  v-for="(option, j) in item.filter((data) => data !== item[0])"
                  :value="`${item[0].key}=${option.id}&`"
                  :label="option.name"
                ></el-option>
              </el-select>
            </div>
            <div class="d-flex justify-content-end">
              <button @click="clear()" class="btn btn-light-danger me-4">
                Clear
              </button>
              <button @click="filter()" class="btn btn-light-primary">
                Filter
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { hideModal } from '@/core/helpers/dom';

export default {
  name: 'FilterModal',
  props: {
    name: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: String,
      default: () => '',
    },
  },
  setup(props, { emit }) {
    const params = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    const models = ref([]);
    const modalRef = ref(null);

    props.data.forEach(() => {
      models.value.push('');
    });

    const filter = () => {
      const concat = models.value.reduce((acc, curr) => acc + curr, '');

      params.value = concat;

      hideModal(modalRef.value);
    };

    const clear = () => {
      models.value.forEach((item, i) => {
        models.value[i] = '';
      });
    };

    return { params, models, modalRef, filter, clear };
  },
};
</script>

<style></style>
