import ApiService from '@/core/services/ApiService';

const edit = async (
  item: Record<string, unknown>,
  params: string
) => {
  await ApiService.patch(`${params}/${item.id}`, item);
};

export { edit };
